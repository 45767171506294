body {
  background-color: #14110f;
  color: #fefdff;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  zoom: 1.05;
}

a {
  color: #ff7f11;
  text-decoration: none;
}
